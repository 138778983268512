import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import( '../views/home.vue'),
    meta: {
      title: '数据看板',
      navHide: true,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/login.vue'),
    meta: {
      title: '登录',
      navHide: true,
    }
  },
  {
    path: '/area',
    name: 'area-count',
    component: () => import( '../views/area.vue'),
    meta: {
      title: '场馆预约详情',
    }
  },
  {
    path: '/show',
    name: 'show-show',
    component: () => import( '../views/show.vue'),
    meta: {
      title: '演出金额详情',
    }
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
