import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/style.less'
import { Toast, Button, Cell, Loading, NavBar, Icon, Calendar, List  } from 'vant'
import 'vant/lib/index.css'

Vue.config.productionTip = false

Vue.prototype.$toast = Toast
Vue.use(Button)
Vue.use(Cell)
Vue.use(Loading)
Vue.use(NavBar)
Vue.use(Icon)
Vue.use(Calendar)
Vue.use(List)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
