<template>
  <div id="app">
    <Navbar v-if="showNav" />
    <router-view />
  </div>
</template>

<script>
import Navbar from '@/components/navbar.vue'
export default {
  name: 'app',
  components: {
    Navbar
  },
  computed: {
    showNav() {
      return !this.$route.meta.navHide;
    }
  },
  mounted(){
  },
}

</script>
<style>
#app{
  min-height: 100vh;
  background: #f6f6f6;
}
input {
  -webkit-appearance: none; /* Safari 和 Chrome */
  -moz-appearance: none; /* Firefox */
  appearance: none;
  background-color: transparent; /* 可选，如果还想去除背景颜色 */
}
</style>
