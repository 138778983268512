<template>
  <van-nav-bar
    :title="title"
    left-text="返回"
    left-arrow
    @click-left="onClickLeft"
  />
</template>

<script>
export default {
  name: 'navbar',
  computed: {
    title() {
      return this.$route.meta.title
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
.van-nav-bar {
  position: sticky;
  top: 0;
  /deep/ .van-nav-bar__text,
  /deep/ .van-icon {
    color: #444;
  }
}
</style>
